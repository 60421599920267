export enum EventNavigationNames {
	Back = 'navigate back',
	To = 'navigate to',
	LogOut = 'log out',
	StartRecording = 'start recording'
}

export enum EventRecallNames {
	ChangePlanStatus = 'change plan status',
	ChangePlanStatusAccepted = 'change plan status to accepted',
	ChangePlanStatusDraft = 'change plan status to draft',
	ChangePlanStatusPlanned = 'change plan status to planned',
	DownloadPlanPdf = 'download plan pdf',
	DownloadVrsPdf = 'download vrs pdf',
	ResetPlantingPlans = 'reset planting plans',
	DownloadCSIPdf = 'download crop stress index pdf',
}

export enum EventFieldPlanNames {
	SelectField = 'select a field',
	SelectAllSeries = 'select all series',
	SelectCrop = 'select a crop',
	ChangeSeedsRequiredCount = 'change seeds required count',
	SelectTrait = 'select a trait',
	ChangeRelativeMaturity = 'change relative maturity',
	ChangeIrrigation = 'change irrigation',
	ChangeFungicide = 'change fungicide',
	ChangeConsistency = 'change consistency',
	ChangeTopEndYield = 'change top end yield',
	ClearFilters = 'clear filters',
	CreateFieldPlan = 'create field plan',
	UpdateCropPlan = 'update crop plan',
	RerunCropPlan = 'rerun crop plan',
	CreateCropPlan = 'create crop plan',
	DeleteCropPlan = 'delete crop plan',
	DownloadFieldPlanPdf = 'download plan pdf',
	DeleteFinalCropPlan = 'delete final crop plan',
	ChangeAgronomicRating = 'change agronomic rating',
	ChangeHybridAcresOnField = 'change hybrid acres on field',
	ChangeHybridRateOnField = 'change hybrid rate on field',
	AddHybridToField = 'add hybrid to field',
	RemoveHybridFromField = 'remove hybrid from field',
	ChangeHybridTraitOnField = 'change hybrid trait on field',
	ViewFieldPlan = 'view field plan',
	SelectCropPlan = 'select crop plan',
	NextField = 'next field',
	PreviousField = 'previous field',
	TransferField = 'transfer field',
	EditFields = 'edit fields',
	EditProducts = 'edit products',
	RenameCropPlan = 'rename crop plan',
	ChangeRequestedTrait = 'change requested trait'
}

export enum EventMaxScriptNames {
	AddCompetitor = 'add competitors',
	DeleteCompetitor = 'delete competitors',
	SelectCrop = 'select a crop',
	ChangeFieldTargetYield = 'change target yield on field',
	ChangeHybridAcresOnField = 'change hybrid acres on field',
	ChangeHybridRateOnField = 'change hybrid rate on field',
	AddOrRemoveHybridToField = 'add or remove hybrid to field',
	NextField = 'next field',
	PreviousField = 'previous field',
	EditFields = 'edit fields',
	ImportOrder = 'import order',
}

export enum EventFieldActivities
{
	ViewWeather = 'View the weather data for a field'
}

export type EventNames = (
	EventRecallNames 
	| EventNavigationNames
	| EventFieldPlanNames
	| EventMaxScriptNames
	| EventFieldActivities
);
