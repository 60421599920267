
import { themeGet } from '@styled-system/theme-get';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { AppDispatch, RootState } from '../../../logic/store/Store';
import { dynamicSort, makeDispatch } from '../../../logic/Utility/Utils';
import { Link } from 'react-router-dom';
import { ReactComponent as LeftCaret } from '../../Dashboard/DashboardTable/Icons/LeftCaret.svg';
import { ReactComponent as Ellipse } from '../../../assets/images/Ellipse.svg';
import { Button } from '../../../components/Button/Button';
import { DatePicker, Divider, InputNumber } from 'antd';
import { ConfirmationFieldRow } from './ConfirmationFieldRow';
import moment from 'moment';
import { cornId, CropConfig, soyId, unknownId } from '../../../logic/store/Seeds/CropsSlice';
import { RoundAcres, RoundSeedingRate } from '../../../logic/Utility/CalculationUtilities';
import { getSeedsForGrower } from '../../../logic/store/Seeds/SeedsActions';
import { CoveringLoader } from '../../../components/LoadingSpinner/CoveringLoader';
import { ScaleLoader } from 'react-spinners';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { IOptionItem } from '../../../components/Dropdown/IOptionItem';
import { confirmBulkFields, IBulkUpdateFieldsRequest } from '../../../logic/store/Grower/SeedAssignmentThunks';
import { AssignmentSource, AssignmentType } from '../../../logic/Models/Requests/SourceUpdateTypes';
import { CompetitorSeedsModal } from '../MaxScript/Competitor Seeds/CompetitorSeedsModal';
import { ICompetitorObject, ICompetitorProduct } from '../../../logic/Models/Responses/CompetitorResponse';
import { ICompetitorSeedItem } from '../MaxScript/Competitor Seeds/CompetitorRow';
import _ from 'lodash';
import { IHybridResponse } from '../../../logic/Models/Responses/SeedResponse';
import { getCompetitorData } from '../../../logic/store/Seeds/SeedsActions';
import { getGrowerOrdersForYear } from '../../../logic/store/Grower/OrderThunks';

const ConfirmationMainContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	margin-left: 70px;
`;

const BackToGrowerListContainer = styled.div`
	display: flex;
	height: 52px;
	padding: 10px 25px;
	align-items: center;
`;

const GrowersListHeaderTitle = styled.span`
	color: ${themeGet('colors.lightGrey')};
	font-family: ${themeGet('fonts.heading')};
`;

const GrowerNameHeaderTitle = styled.span`
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.large')};
	font-family: ${themeGet('fonts.heading')};
	width: 60%;
`;

const ConfirmationInnerContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	padding-right: 1%;
	padding-left: 1%;
	flex-direction: column;
	overflow: hidden;
	padding-bottom: 15px;
`;

const TitleMainContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const PageTitle = styled.div`
	font-weight: ${themeGet('fontWeights.bold')};
	font-size: ${themeGet('fontSizes.xLarge')};
	font-family: ${themeGet('fonts.heading')};
`;

const TitleButtonContainer = styled.div`
	margin-left: auto;
`;

const StyledDivider = styled(Divider)`
	&.ant-divider {
		border-top-color: black;
	}
`;

const StyledDatePicker = styled(DatePicker)`
	border-radius: 4px;
	width: 88%;
	padding: 2px 5px 2px;
	&:focus {
		outline: none;
		box-shadow: 0 0 1pt 1pt white;
	}
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-picker-focused {
		border-color: black;
	}
`;

const StyledDatePickerPanel = styled.div`
	.ant-picker-today-btn {
		color: ${themeGet('colors.primary')};
	}
	.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
		border-width: 1px;
		border-style: solid;
		border-color: ${themeGet('colors.secondary')};
	}
	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
		background: ${themeGet('colors.primary')};
	}
`;

export const NumberInput = styled(InputNumber)`
	width: 60px;
	height: 25px;
	font-size: ${themeGet('fontSizes.small')};
	margin-right: -2px;
	border-radius: 4px;
	border: thin solid ${themeGet('colors.lightestGrey')};
	&:focus {
		outline: none;
		box-shadow: 0 0 1pt 1pt white;
	}
	&:focus:not(:disabled),
	&:hover:not(:disabled),
	&:active:not(:disabled) {
		border-color: black;
		background-color: ${themeGet('colors.white')};
	}
	.ant-input-number-handler-wrap
	{
		display: none;
	}
	.ant-input-number-input {
		text-align: left;
		height: auto;
		padding-right: 3px;
	}
`;

export interface IConfirmedField
{
	Acres: number;
	CropName: string;
	CropId: string;
	FarmName: string;
	Id: string;
	Name: string;
	PlantingDate: string;
	HybridsToConfirm: IConfirmHybrid[];
}

export interface IConfirmHybrid
{
	Area: number;
	BrandName?: string;
	HybridName: string;
	HybridId: string;
	IsExternal: boolean;
	Treatment?: string;
	Rate: number;
	Rm?: string;
}

export const cropOptions: IOptionItem[] =
[
	{
		label: 'Corn',
		value: cornId
	},
	{
		label: 'Soybean',
		value: soyId
	},
	{
		label: 'Other',
		value: unknownId
	}
];

const mapStateToProps = (state: RootState) => 
{
	const SelectedGrower = state.grower.Growers.find(g => g.Id === state.ui.SelectedGrowerId);
	return {
		AllFields: SelectedGrower?.Farms.flatMap(fa => fa.Fields),
		AllFarmsWithFields: SelectedGrower?.Farms,
		CompetitorBrandsAndProducts: state.ui.CompetitorBrandsAndProducts,
		CompetitorData: state.seeds.competitors,
		CurrentCropYear: state.crops.CropYear,
		IsLoadingGrower: state.grower.isLoading,
		IsLoadingSeedsForGrower: state.seeds.isLoading,
		IsLoadingCompetitors: state.seeds.isLoadingCompetitors,
		Seeds: state.seeds.allSeeds,
		SelectedFieldIds: state.ui.SelectedFields,
		SelectedGrower,
	};
};
	
const mapDispatchToProps = (dispatch: AppDispatch) =>
{
	return {
		ConfirmBulkFields: makeDispatch(dispatch, confirmBulkFields),
		GetCompetitors: makeDispatch(dispatch, getCompetitorData),
		GetGrowerOrders: makeDispatch(dispatch, getGrowerOrdersForYear),
		GetSeedsForGrower: makeDispatch(dispatch, getSeedsForGrower),
	};
};
	
const connector = connect(mapStateToProps, mapDispatchToProps);
	type PropsFromRedux = ConnectedProps<typeof connector>;

interface IFieldConfirmationProps extends PropsFromRedux
{
}

const FieldConfirmationMainComponent = (props: IFieldConfirmationProps) =>
{
	const {
		AllFarmsWithFields,
		AllFields,
		CompetitorBrandsAndProducts,
		CompetitorData,
		CurrentCropYear,
		IsLoadingGrower,
		IsLoadingSeedsForGrower,
		IsLoadingCompetitors,
		Seeds,
		SelectedFieldIds,
		SelectedGrower,
		ConfirmBulkFields,
		GetCompetitors,
		GetGrowerOrders,
		GetSeedsForGrower,
	} = props;

	const [plantingAllDate, setPlantingAllDate] = useState<string>(undefined);
	const [cropIdAll, setCropIdAll] = useState<string>(undefined);
	const [soyRateAll, setSoyRateAll] = useState<number>(undefined);
	const [cornRateAll, setCornRateAll] = useState<number>(undefined);
	const [fieldsToConfirm, setFieldsToConfirm] = useState<IConfirmedField[]>([]);
	const [areFieldsScrollable, setAreFieldsScrollable] = useState<boolean>(false);
	const [showCompetitorModal, setShowCompetitorModal] = useState<boolean>(false);
	const [competitorCropId, setCompetitorCropId] = useState<string>(undefined);
	const [competitorFieldId, setCompetitorFieldId] = useState<string>(undefined);
	const [combinedCompetitors, setCombinedCompetitors] = useState<ICompetitorObject[]>([]);
	const [cornSeeds, setCornSeeds] = useState<IHybridResponse[]>([]);
	const [soySeeds, setSoySeeds] = useState<IHybridResponse[]>([]);

	// Create a fake external hybrid id to give us a better way of tracking externals to avoid duplicates, etc
	const createFakeExternalHybridId = (externalBrandName: string, externalProductName?: string, externalRm?: string) =>
	{
		const fakeExternalHybridId = `${externalBrandName}_${externalProductName ? externalProductName : null}_${externalRm ? externalRm : null}`;

		return fakeExternalHybridId;
	};

	useEffect(() =>
	{
		GetCompetitors();
		GetGrowerOrders({ growerId: SelectedGrower.Id, year: Number.parseInt(CurrentCropYear) });
	},[]);

	useEffect(() =>
	{
		if (!IsLoadingSeedsForGrower && Seeds?.length > 0)
		{
			const allSeeds = _.cloneDeep(Seeds).flatMap(s => s.BrandApplications.flatMap(ba => ba.Hybrids));
			setCornSeeds([...allSeeds.filter(h => h.CropId === cornId)]);

			setSoySeeds([...allSeeds.filter(h => h.CropId === soyId)]);
		}
	},[Seeds, IsLoadingSeedsForGrower]);

	const theme = useTheme();
	const dateFormat = 'MM/DD/YYYY';

	const ref = useRef(null);

	useLayoutEffect(() =>
	{
		if (fieldsToConfirm.length > 0)
		{
			const element = ref.current as Element;
			const isScrollable = element ? element.scrollHeight > element.clientHeight : false;
			setAreFieldsScrollable(isScrollable);
		}

		setAreFieldsScrollable(false);
		
	},[fieldsToConfirm, ref, ref.current]);

	useEffect(() =>
	{
		const element = ref.current as Element;
		const isScrollable = element ? element.scrollHeight > element.clientHeight : false;

		setAreFieldsScrollable(isScrollable);

	}),[areFieldsScrollable];

	const backToFieldList = () =>
	{
		// Do anything needed before returning to the field list
	};

	const confirmFields = () =>
	{
		const request: IBulkUpdateFieldsRequest =
		{
			Type: AssignmentType.Actual,
			SourceType: AssignmentSource.Manual,
			SourceName: 'Bulk Update',
			Fields: fieldsToConfirm.map(f =>
			{
				return({
					FieldId: f.Id,
					PlantingDate: moment(f.PlantingDate).utc().format(),
					Hybrids: f.HybridsToConfirm.filter(h => h.HybridId !== null).map(h =>
					{
						return({
							AppliedAcres: h.Area,
							IsSyngenta: h.IsExternal ? false : true,
							IsExternal: h.IsExternal ? true : false,
							Name: h.IsExternal ? h.HybridName : null,
							Brand: h.IsExternal ? h.BrandName : null,
							Id: h.IsExternal ? null : h.HybridId,
							Rate: h.Rate,
							Rm: h.Rm ? Number.parseFloat(h.Rm) : null,
						});
					})
				});
			})
		};

		ConfirmBulkFields(request);
	};

	useEffect(() =>
	{
		if (!Seeds && !IsLoadingSeedsForGrower)
		{
			GetSeedsForGrower();
		}
	},[IsLoadingSeedsForGrower]);

	useEffect(() =>
	{
		if (SelectedFieldIds?.length > 0 && AllFields?.length > 0 && Seeds?.length > 0 && (cornSeeds.length > 0 && soySeeds.length > 0))
		{
			if (fieldsToConfirm.length > 0)
			{
				return;
			}

			// Map the selected fields from the list of selected fields
			const selectedFields: IConfirmedField[] = AllFields.filter(f => SelectedFieldIds.findIndex(sf => sf === f.Id) > -1)
				.map(field =>
				{
					return({
						Id: field.Id,
						Name: field.Name,
						PlantingDate: field.PlantingDate ? new Date(field.PlantingDate).toLocaleDateString('en-US') : '',
						Acres: RoundAcres(field.Area),
						FarmName: AllFarmsWithFields.find(fa => fa.Fields.findIndex(f => f.Id === field.Id) > -1).Name,
						CropName: field.CurrentCrop,
						CropId: field.CurrentCropId,
						HybridsToConfirm: field.CurrentCropId === unknownId ? [] : field.Seeds.map(s =>
						{
							let externalHybridId = undefined;
							if (s.IsExternal)
							{
								externalHybridId = createFakeExternalHybridId(s.BrandName, s.HybridName, s.RelativeMaturity);
								const externalSeedAppliedToField: IHybridResponse = {
									AnaplanForecast: 0,
									Availability: undefined,
									BrandId: undefined,
									BrandName: s.BrandName,
									CropId: field.CurrentCropId,
									Id: externalHybridId,
									IsExternal: true,
									LocalPositioning: undefined,
									MaterialAvailability: [],
									Name: s.HybridName,
									Pricing: undefined,
									RelativeMaturity: s.RelativeMaturity,
									SeriesId: undefined,
									SeriesName: undefined,
									TraitId: undefined,
									TraitFullName: undefined,
									TraitName: undefined,
								};
								if (field.CurrentCropId === cornId)
								{
									const updatedSeeds = cornSeeds;
									if (updatedSeeds.findIndex(us => us.Id === externalHybridId) === -1)
									{
										updatedSeeds.push(externalSeedAppliedToField);
										setCornSeeds([...updatedSeeds]);
									}
								}
								else
								{
									const updatedSeeds = soySeeds;
									if (updatedSeeds.findIndex(us => us.Id === externalHybridId) === -1)
									{
										updatedSeeds.push(externalSeedAppliedToField);
										setSoySeeds([...updatedSeeds]);
									}
								}
							}
							return({
								Area: RoundAcres(s.Area),
								BrandName: s.IsExternal ? s.BrandName : null,
								HybridName: s.HybridName,
								HybridId: s.IsExternal ? externalHybridId : s.HybridId,
								IsExternal: s.IsExternal,
								Rate: RoundSeedingRate(s.Rate),
								Rm: s.RelativeMaturity,
							});
						})
					});
				});

			selectedFields.forEach(f =>
			{
				f.HybridsToConfirm.sort(dynamicSort('Area', 'descending'));
				if (f.CropId !== unknownId)
				{
					f.HybridsToConfirm.push({
						Area: undefined,
						HybridName: undefined,
						HybridId: null, // The dropdown has some trouble displaying only the placeholder if the id is anything but null
						IsExternal: false,
						Rate: undefined,
					});
				}
			});

			setFieldsToConfirm(selectedFields);
		}
	},[SelectedFieldIds, Seeds, cornSeeds, soySeeds]);

	// Planting should only be between 3/01 and 7/15
	const disabledPlantingDates = (currentDate: moment.Moment): boolean =>
	{
		const compareDate = moment(currentDate, dateFormat);
		// Dates can only be between April 1 and July 15th of the current year
		const marchDate = moment(`04/01/${moment().year()}`, dateFormat);
		const julyDate = moment(`07/15/${moment().year()}`, dateFormat);

		// the [] means both beginning and end dates are inclusive, () would mean exclusive
		// you can mix and match (] or [) as well.
		return !compareDate.isBetween(marchDate, julyDate, 'day', '[]');
	};

	const plantingEditDate = (fieldId: string, updatedValue: string) =>
	{	
		const updatedFields = fieldsToConfirm.map(field =>
		{
			if (fieldId === 'all')
			{
				// Apply value to all
				field.PlantingDate = updatedValue;
				setPlantingAllDate(updatedValue);
				return field;
			}
			else if (field.Id === fieldId)
			{
				// Only update if the value has not changed
				if (field.PlantingDate !== updatedValue)
				{
					// If there is an all-apply value, clear it
					if (plantingAllDate)
					{
						setPlantingAllDate(undefined);
					}
					field.PlantingDate = updatedValue;
					return field;
				}
			}
			return field;
		});

		setFieldsToConfirm([...updatedFields]);
	};

	const editAllRate = (cropId: string, newRateValue: number) =>
	{
		fieldEditHybridRate('all', cropId, newRateValue);
	};

	// hybridId will be the cropId if the fieldId === 'all'
	const fieldEditHybridRate = (fieldId: string, hybridId: string, updatedValue: number ) =>
	{
		const updatedFields = fieldsToConfirm.map(field =>
		{
			const cropConfig = CropConfig()[field.CropId];

			if (fieldId === 'all' && field.CropId === hybridId && updatedValue > 0)
			{
				let adjustedValue = updatedValue;
				// Make sure the rate is within the field's rate boundaries
				if (updatedValue < cropConfig.minSeedingRate)
				{
					adjustedValue = cropConfig.minSeedingRate;
				}
				if (updatedValue > cropConfig.maxSeedingRate)
				{
					adjustedValue = cropConfig.maxSeedingRate;
				}

				if (field.HybridsToConfirm.length > 0)
				{
					field.HybridsToConfirm.filter(h => h.HybridId !== null).forEach(h => h.Rate = adjustedValue);
				}
				
				if (hybridId === cornId)
				{
					setCornRateAll(updatedValue);
				}
				else if (hybridId === soyId)
				{
					setSoyRateAll(updatedValue);
				}
			}
			else if (field.Id === fieldId && updatedValue >= 0)
			{
				// Make sure the rate is within the field's rate boundaries
				if (updatedValue < cropConfig.minSeedingRate)
				{
					updatedValue = cropConfig.minSeedingRate;
				}
				if (updatedValue > cropConfig.maxSeedingRate)
				{
					updatedValue = cropConfig.maxSeedingRate;
				}

				if (field.HybridsToConfirm.find(h => h.HybridId === hybridId).Rate !== updatedValue)
				{
					if (field.CropId === cornId)
					{
						setCornRateAll(undefined);
					}
					else if (field.CropId === soyId)
					{
						setSoyRateAll(undefined);
					}

					field.HybridsToConfirm.find(h => h.HybridId === hybridId).Rate = updatedValue;
				}
				
				return field;
			}
			return field;
		});

		setFieldsToConfirm([...updatedFields]);
	};

	const fieldEditHybridAcres = (fieldId: string, hybridId: string, updatedValue: number) =>
	{
		const updatedFields = fieldsToConfirm.map(field =>
		{
			if (field.Id === fieldId && updatedValue > 0)
			{
				field.HybridsToConfirm.find(h => h.HybridId === hybridId).Area = updatedValue;
				return field;
			}
			return field;
		});
		
		setFieldsToConfirm([...updatedFields]);
	};

	const changeFieldCrop = (fieldId: string, newCropId: string) =>
	{
		const updatedFields = fieldsToConfirm.map(field =>
		{
			if (fieldId === 'all')
			{
				// Apply value to all -- if the crop is being changed, remove the current hybrids
				if (field.CropId !== newCropId)
				{
					field.HybridsToConfirm = [];

					if (newCropId !== unknownId)
					{	
						// Re-add an empty hybrid so that a new one can be added
						field.HybridsToConfirm.push({
							Area: undefined,
							HybridName: undefined,
							HybridId: null, // The dropdown has some trouble displaying only the placeholder if the id is anything but null
							IsExternal: false,
							Rate: undefined,
						});
					}
				}
				field.CropId = newCropId;
				setCropIdAll(newCropId);
				return field;
			}
			else if (field.Id === fieldId)
			{
				// Update the crop id and then remove any hybrids on the field
				field.CropId = newCropId;

				field.HybridsToConfirm = [];

				// If there is an all-apply value, clear it
				if (cropIdAll)
				{
					setCropIdAll(undefined);
				}

				if (newCropId !== unknownId)
				{
					// Re-add an empty hybrid so that a new one can be added
					field.HybridsToConfirm.push({
						Area: undefined,
						HybridName: undefined,
						HybridId: null, // The dropdown has some trouble displaying only the placeholder if the id is anything but null
						IsExternal: false,
						Rate: undefined,
					});
				}

				return field;
			}
			return field;
		});
		
		setFieldsToConfirm([...updatedFields]);
	};

	const changeFieldHybrid = (fieldId: string, updatedHybridId: string, previousHybridId: string) =>
	{
		if (updatedHybridId === 'create_competitor')
		{
			const foundField = fieldsToConfirm.find(f => f.Id === fieldId);
			setCompetitorFieldId(foundField.Id);
			setCompetitorCropId(foundField.CropId);
			setShowCompetitorModal(true);
			return;
		}

		const updatedFields = fieldsToConfirm.map(field =>
		{
			if (field.Id === fieldId)
			{
				// Update the previous Hybrid to the updated one
				const availableFieldSeeds = field.CropId === cornId ? cornSeeds : soySeeds;
				const newSeed = availableFieldSeeds.find(afs => afs.Id === updatedHybridId);
				field.HybridsToConfirm.find(h => h.HybridId === previousHybridId).HybridName = newSeed.Name;
				field.HybridsToConfirm.find(h => h.HybridId === previousHybridId).BrandName = newSeed.BrandName;
				field.HybridsToConfirm.find(h => h.HybridId === previousHybridId).IsExternal = newSeed.IsExternal;
				field.HybridsToConfirm.find(h => h.HybridId === previousHybridId).Rm = newSeed.RelativeMaturity;
				field.HybridsToConfirm.find(h => h.HybridId === previousHybridId).HybridId = newSeed.Id;

				if (previousHybridId === null)
				{
					// Set acres to whatever the field has left that is not assigned yet
					const totalAssignedAcreage = field.HybridsToConfirm.reduce((sum, h) => sum + (h.Area ?? 0), 0);
					if (totalAssignedAcreage < field.Acres)
					{
						field.HybridsToConfirm.find(h => h.HybridId === updatedHybridId).Area = field.Acres - totalAssignedAcreage;
					}

					// Re-add an empty hybrid so that a new one can be added
					field.HybridsToConfirm.push({
						Area: undefined,
						HybridName: undefined,
						HybridId: null, // The dropdown has some trouble displaying only the placeholder if the id is anything but null
						IsExternal: false,
						Rate: undefined,
					});
				}

				return field;
			}
			return field;
		});

		setFieldsToConfirm([...updatedFields]);
	};

	const removeHybridFromField = (fieldId: string, hybridToRemoveId: string) =>
	{
		const updatedFields = fieldsToConfirm.map(field =>
		{
			if (field.Id === fieldId)
			{
				field.HybridsToConfirm = field.HybridsToConfirm.filter(h => h.HybridId !== hybridToRemoveId);

				return field;
			}
			return field;
		});

		setFieldsToConfirm([...updatedFields]);
	};

	const isConfirmDisabled = (): boolean =>
	{
		// If any planting dates are empty, the button should be disabled
		if (fieldsToConfirm.some(f => !f.PlantingDate))
		{
			return true;
		}

		// A field must have at least one viable hybrid, so filter out any null hybrid and then check the length
		if (fieldsToConfirm.filter(f => f.CropId !== unknownId).some(f => f.HybridsToConfirm.filter(h => h.HybridId !== null).length === 0))
		{
			return true;
		}

		// If any field has a hybrid that is not null and does not have a rate and acreage, the button should be disabled
		const allFieldHybrids = fieldsToConfirm.flatMap(f => f.HybridsToConfirm);
		const viableFieldHybrids = allFieldHybrids.filter(h => h.HybridId !== null).filter(h => !h.IsExternal);

		if (viableFieldHybrids.some(h => !(h.Area && h.Area > 0) || !(h.Rate && h.Rate > 0)))
		{
			return true;
		}

		return false;
	};

	const changeCropAll = (updatedCropId: string) =>
	{
		changeFieldCrop('all', updatedCropId);
	};

	useEffect(() =>
	{
		// Combine the official competitor list with the hand written values
		if (Object.values(CompetitorData).length > 0 && competitorCropId)
		{
			const officialCompetitorDataForCrop = _.cloneDeep(CompetitorData[competitorCropId]);
			const manualEnteredData = CompetitorBrandsAndProducts;
			const combinedData: ICompetitorObject[] = officialCompetitorDataForCrop;
			Object.keys(manualEnteredData).forEach(k =>
			{
				const combinedDataIndex = combinedData.findIndex(c => c.BrandName.toLowerCase() === k.toLowerCase());
				if (combinedDataIndex < 0)
				{
					const manualEnteredProducts = manualEnteredData[k];
					const convertedProducts: ICompetitorProduct[] = [];
					manualEnteredProducts.forEach(hp =>
					{
						convertedProducts.push(
							{
								Id: '',
								ProductName: hp,
								RelativeMaturity: ''
							});
					});
					const manualEnteredCompetitor: ICompetitorObject =
					{
						Id: '',
						BrandName: k,
						Products: convertedProducts,
						Year: Number.parseInt(CurrentCropYear)
					};
					combinedData.push(manualEnteredCompetitor);
				}
				else // Key already exists as a Brand
				{
					const manualEnteredProducts = manualEnteredData[k];
					const products = combinedData[combinedDataIndex].Products;
					manualEnteredProducts.forEach(p =>
					{
						if (products.findIndex(prod => prod.ProductName.toLowerCase() === p.toLowerCase()) < 0)
						{
							combinedData[combinedDataIndex].Products.push(
								{
									Id: '',
									ProductName: p,
									RelativeMaturity: ''
								});
						}
					});
				}
			});

			setCombinedCompetitors([...combinedData]);
		}
	},[CompetitorData, competitorCropId, CompetitorBrandsAndProducts]);

	const closeCompetitorSeedsModal = () =>
	{
		setShowCompetitorModal(false);
		setCompetitorCropId(undefined);
		setCompetitorFieldId(undefined);
	};

	// Add new competitors to Product Inventory and to Field Inventories as unselected
	const addNewCompetitors = (newCompetitors: ICompetitorSeedItem[], cropId: string, fieldId: string) =>
	{
		const updatedSeeds = cropId === cornId ? cornSeeds : soySeeds;
		
		const updatedFields = fieldsToConfirm.map(f =>
		{
			newCompetitors.forEach(nc =>
			{
				const fakeExternalHybridId = createFakeExternalHybridId(nc.Brand, nc.Product, nc.RM?.toString());
				
				// Add the Hybrid to the field that was being updated
				if (f.Id === fieldId)
				{
					if (f.HybridsToConfirm.findIndex(h => h.HybridId === fakeExternalHybridId) === -1)
					{
						f.HybridsToConfirm.find(h => h.HybridId === null).BrandName = nc.Brand;
						f.HybridsToConfirm.find(h => h.HybridId === null).HybridName = nc.Product;
						f.HybridsToConfirm.find(h => h.HybridId === null).Rm = nc.RM?.toString();
						f.HybridsToConfirm.find(h => h.HybridId === null).IsExternal = true;
						f.HybridsToConfirm.find(h => h.HybridId === null).HybridId = fakeExternalHybridId;

						// If we're only adding one competitor -- see if there is any remaining acreage to assign to it
						if (newCompetitors.length === 1)
						{
							// Set acres to whatever the field has left that is not assigned yet
							const totalAssignedAcreage = f.HybridsToConfirm.reduce((sum, h) => sum + (h.Area ?? 0), 0);
							if (totalAssignedAcreage < f.Acres)
							{
								f.HybridsToConfirm.find(h => h.HybridId === fakeExternalHybridId).Area = f.Acres - totalAssignedAcreage;
							}
						}

						// Re-add an empty hybrid so that a new one can be added
						f.HybridsToConfirm.push({
							Area: undefined,
							HybridName: undefined,
							HybridId: null, // The dropdown has some trouble displaying only the placeholder if the id is anything but null
							IsExternal: false,
							Rate: undefined,
						});
					}
				}
	
				// Add it to the overall dropdown of seeds -- if it does not already exist
				if (updatedSeeds.findIndex(us => us.Id === fakeExternalHybridId) === -1)
				{
					updatedSeeds.push(
						{
							AnaplanForecast: 0,
							Availability: undefined,
							BrandId: undefined,
							BrandName: nc.Brand,
							CropId: cropId,
							Id: fakeExternalHybridId,
							IsExternal: true,
							LocalPositioning: undefined,
							MaterialAvailability: [],
							Name: nc.Product,
							Pricing: undefined,
							RelativeMaturity: nc.RM ? nc.RM.toString() : null,
							SeriesId: undefined,
							SeriesName: undefined,
							TraitId: undefined,
							TraitFullName: undefined,
							TraitName: undefined,
						}
					);
				}
			});

			return f;
		});

		setFieldsToConfirm([...updatedFields]);

		if (cropId === cornId)
		{
			setCornSeeds([...updatedSeeds]);
		}
		else
		{
			setSoySeeds([...updatedSeeds]);
		}
	};

	const saveCompetitors = (newCompetitors: ICompetitorSeedItem[], deletedCompetitors: ICompetitorSeedItem[]) =>
	{
		addNewCompetitors(newCompetitors, competitorCropId, competitorFieldId);

		// Ignore deleting competitors as we do not need to
	};

	const saveCompetitorsAndCloseModal = (newCompetitors: ICompetitorSeedItem[], deletedCompetitors: ICompetitorSeedItem[]) =>
	{
		// Save
		saveCompetitors(newCompetitors, deletedCompetitors);
		// Close the modal
		setShowCompetitorModal(false);
		// Clear the Competitor crop & field id
		setCompetitorCropId(undefined);
		setCompetitorFieldId(undefined);
	};

	const showModal = useCallback(() =>
	{
		if (showCompetitorModal)
		{
			return (
				<CompetitorSeedsModal
					competitorBrandsAndProducts={combinedCompetitors}
					cropId={competitorCropId}
					productInventory={[]}
					visible={showCompetitorModal}
					onCancel={closeCompetitorSeedsModal}
					saveCompetitors={saveCompetitorsAndCloseModal}
				/>
			);
		}
		else
		{
			return (
				<></>
			);
		}
	},[showCompetitorModal, competitorCropId, combinedCompetitors, closeCompetitorSeedsModal, saveCompetitorsAndCloseModal]);

	return (
		<ConfirmationMainContainer className='ConfirmationMainContainer'>
			<BackToGrowerListContainer className='backTo_FieldList_Container'>
				<Link to='/fieldactivities' className='linkTo_FieldList' onClick={backToFieldList}>
					<LeftCaret style={{ marginRight: '20px' }} />
					<GrowersListHeaderTitle>
						Field List
					</GrowersListHeaderTitle>
				</Link>
				<Ellipse style={{ margin: '0 10px' }} />
				<GrowerNameHeaderTitle className='Stewardship_Title_GrowerName'>
					{SelectedGrower.Name}
				</GrowerNameHeaderTitle>
			</BackToGrowerListContainer>
			<ConfirmationInnerContainer className='ConfirmationInnerContainer'>
				<TitleMainContainer className='TitleMainContainer'>
					<PageTitle className='Stewardship_Contract_Title'>
						{`Field Confirmation ${CurrentCropYear}`}
					</PageTitle>
					<TitleButtonContainer className='TitleButtonContainer'>
						<Button
							disabled={isConfirmDisabled()}
							variant='main'
							width={'120px'}
							onClick={() => confirmFields()}
						>Confirm</Button>
					</TitleButtonContainer>
				</TitleMainContainer>
				<StyledDivider className='Divider' />
				{
					!IsLoadingSeedsForGrower &&
					<div className='ConfirmationEditingContainer' style={{ display: 'flex', flexDirection: 'row', height: '100%', overflow: 'hidden' }}>					
						<div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
							<div
								style={{
									backgroundColor: 'black',
									color: 'white',
									fontWeight: theme.fontWeights.bold,
									display: 'flex',
									flexDirection: 'row',
									fontSize: 12,
									borderTopLeftRadius: 6,
									borderTopRightRadius: 6,
									paddingLeft: 10,
									paddingRight: 10,
									paddingTop: 5,
									paddingBottom: 5
								}}>
								<div style={{ width: '12%', textAlign: 'left' }}>Field</div>
								<div style={{ width: areFieldsScrollable ? '11.5%' : '12%', textAlign: 'left' }}>Farm Name</div>
								<div style={{ width: '12%', textAlign: 'left' }}>Crop</div>
								<div style={{ width: '5%', textAlign: 'left' }}>Acres</div>						
								<div style={{ width: areFieldsScrollable ? window.innerWidth <= 1200 ? '44%' : '43.5%' : '45%', textAlign: 'left' }}>Hybrid | Applied Acres | Rate</div>
								<div style={{ width: '15%', textAlign: 'left' }}>Planting Date</div>
							</div>
							<div
								className={'PlantingSummaryRow'}
								key={'Planting_Summary_Row'}
								style={{
									display: 'flex',
									flexDirection: 'row',
									fontSize: 12,
									paddingLeft: 10,
									height: 40,
									alignItems: 'center',
									backgroundColor: theme.colors.lightestGrey
								}}
							>
								<div style={{ width: '12%', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
									<div style={{ display: 'flex', alignItems: 'center'}}>
										Apply for all
									</div>
								</div>
								<div style={{ width: areFieldsScrollable ? '11.5%' : '12%', textAlign: 'left' }}>
									{''}
								</div>
								<div style={{ width: window.innerWidth <= 1024 ? '17%' : '12%', display: 'flex', alignItems: 'center'}}>
									<div style={{ width: window.innerWidth <= 1200 ? '90%' : '70%' }}>
										<Dropdown
											variant='outlinednarrownopad'
											options={cropOptions}
											onSelect={changeCropAll}
											selected={cropIdAll}
											placeholder='Select a Crop'
											className='Crop_Dropdown_All'
										/>
									</div>
								</div>
								<div style={{ width: window.innerWidth <= 1200 ? 0 : '5%', textAlign: 'left' }}>
									{''}
								</div>
								<div style={{
									width: areFieldsScrollable ? window.innerWidth <= 1200 && window.innerWidth > 1024 ? '49%' : '43.5%' : '45%',
									textAlign: 'left'
								}}>
									<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<div style={{ width: '30%'}}>
											{''}
										</div>
										<div style={{ marginLeft: 10, width: window.innerWidth <= 1024 ? 10 : 60, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
											{''}
										</div>
										{
											fieldsToConfirm?.some(f => f.CropId === cornId) &&
											<div style={{ marginLeft: 10, width: 100, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
												<div style={{ marginRight: 5 }}>Corn: </div>
												<NumberInput
													style={{
														fontSize: 12,
														width: '90%',
														height: 25,
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
													}}
													className={'Hybrid_RateAll_Corn_Input'}
													type='number'
													placeholder='Add Rate'
													value={cornRateAll}
													onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
														editAllRate(cornId, evt.currentTarget.value ? 
															Number(evt.currentTarget.value) 
															: Number(cornRateAll))}
													onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
														editAllRate(cornId, evt.target && (evt.target as HTMLInputElement).value ? 
															Number((evt.target as HTMLInputElement).value)
															: Number(cornRateAll))}
												/>
											</div>
										}
										{
											fieldsToConfirm?.some(f => f.CropId === soyId) &&
											<div style={{ marginLeft: 10, width: 120, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
												<div style={{ marginRight: 5 }}>Soybean: </div>
												<NumberInput
													style={{
														fontSize: 12,
														width: '90%',
														height: 25,
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
													}}
													className={'Hybrid_RateAll_Soy_Input'}
													type='number'
													placeholder='Add Rate'
													value={soyRateAll}
													onBlur={(evt: React.FocusEvent<HTMLInputElement>) =>
														editAllRate(soyId, evt.currentTarget.value ? 
															Number(evt.currentTarget.value) 
															: Number(soyRateAll))}
													onPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) =>
														editAllRate(soyId, evt.target && (evt.target as HTMLInputElement).value ? 
															Number((evt.target as HTMLInputElement).value)
															: Number(soyRateAll))}
												/>
											</div>
										}
									</div>
								</div>
								<div style={{ width: '15%', textAlign: 'left' }}>
									<StyledDatePicker
										className='Planting_ApplyAll_Date'
										placeholder={'mm/dd/yyyy'}
										format={dateFormat}
										disabledDate={disabledPlantingDates}
										value={plantingAllDate ? moment(plantingAllDate) : undefined}
										onChange={(value: moment.Moment) => plantingEditDate('all', value ? value.format(dateFormat) : '')}
										panelRender={originalPanel =>
										{
											return (
												<StyledDatePickerPanel className='StyledDatePickerPanel'>
													{originalPanel}
												</StyledDatePickerPanel>
											);
										}}
									/>
								</div>
							</div>
							<div ref={ref} className={'FieldRows'} style={{ height: '100%', overflowY: 'auto' }}>
								{
									fieldsToConfirm.sort(dynamicSort('Name')).sort(dynamicSort('CropName')).map((f,index) =>
									{
										const fieldSeedList = f.CropId === cornId ? cornSeeds : f.CropId === soyId ? soySeeds : [];
										return(
											<div key={f.Id}>
												<ConfirmationFieldRow
													field={f}
													hybridList={fieldSeedList}
													lastItem={AllFields.length === index + 1}
													changeFieldCrop={changeFieldCrop}
													changeFieldHybrid={changeFieldHybrid}
													disabledDates={disabledPlantingDates}
													editHybridAcres={fieldEditHybridAcres}
													editHybridRate={fieldEditHybridRate}
													editPlantingDate={plantingEditDate}
													removeHybridFromField={removeHybridFromField}
													orderData={SelectedGrower?.OrdersByYear ? _.cloneDeep(SelectedGrower.OrdersByYear[CurrentCropYear]) : []}
												/>
											</div>
										);
									})
								}
							</div>
						</div>
					</div>
				}
				{showModal()}
				<CoveringLoader
					className={IsLoadingSeedsForGrower || fieldsToConfirm?.length === 0 ? 'loading' : ''}
					style={{backgroundColor: '#ddda', display: 'flex', flexDirection: 'column'}}
				>
					<ScaleLoader color={theme.colors.ghxOrangeLM} loading={true} />
					Loading Seeds
				</CoveringLoader>
			</ConfirmationInnerContainer>
		</ConfirmationMainContainer>
	);
};

export const FieldConfirmationMain = connector(FieldConfirmationMainComponent);
